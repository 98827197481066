import React from "react";

import HeroImageMaintanence from "../../assets/images/hero-maintanence-image_2021.jpg";
import HeroImageMaintanence2X from "../../assets/images/hero-maintanence-image_2021@2x.jpg";

const HeroBannerMaintenance = ({ heroBannerClass = "" }) => {
  return (
    <div className={`${heroBannerClass}`}>
      <img
        className="hero-banner__background-image"
        srcSet={`${HeroImageMaintanence} 1x, ${HeroImageMaintanence2X} 2x`}
        type="image/jpeg"
        alt=""
      />
      <div className="hero-banner__outer">
        <section className="hero-banner__content">
          <p className="second">
            <strong>Planned maintenance to our website</strong>
          </p>
          <p className="second">
            Due to planned maintenance, our website may not <br />
            be available between
            <strong>
              {" "}
              8.00pm and 11.00pm on
              <br />
              Tuesday 29<sup>th</sup> October.
            </strong>
          </p>
          <p className="second">We apologise for any inconvenience this may cause.</p>
        </section>
      </div>
    </div>
  );
};

export default HeroBannerMaintenance;
