import React, { useContext } from "react";
import { MenuContext } from "../../context";

const FindOutMoreBtn = ({additionalClass}) => {
  const menuContext = useContext(MenuContext);
  return (
    <section className={`find-out-more-btn ${additionalClass || ''}`}>
      <a
        href="https://www.hsbc.co.uk/accessibility"
        target="_self"        
        inert={menuContext.isMenuOpen ? "true" : null}
      >
        Find out more
      </a>
    </section>
  );
};

export default FindOutMoreBtn;
