import React from "react";
import Layout from "../components/layout";
import HomeHeader from "../components/home-header/home-header.component";
import ServiceStrip from "../components/service-strip/service-strip.component";
import RelatedLinks from "../components/related-links/related-links.component";
import AwardPanel from "../components/award-panel/award-panel.component";
import Rate from "../components/rate/rate.component";
import FaqNewsPanel from "../components/faq-news-panel/faq-news-panel.component";
import BrokerSupport from "../components/broker-support/broker-support.component";
import "../styles/app.scss";
import LinkToGreenPage from "../components/green-page/link-to-greenpage.component";
import { triggerTealiumEvent } from "../utils/tealiumUtils";
import { useLocation } from "@reach/router";

export default function Home() {
  const location = useLocation();
  if (typeof window !== "undefined" && window.utag) {
    triggerTealiumEvent({
      tealiumEvent: "view",
      pageUrl: location.href,
      pageType: "homepage",
      pageSubCategory: "Home",
      rawDataLayer: "10451v1",
    });
  }

  return (
    <Layout>
      <HomeHeader />
      <ServiceStrip />
      <LinkToGreenPage />
      <RelatedLinks />
      <AwardPanel />
      <Rate />
      <FaqNewsPanel />
      <BrokerSupport />
    </Layout>
  );
}
