import React from "react";
import HomepageBanner from "../../assets/images/banner_homepage.jpg";
import FindOutMoreBtn from "../find-out-more-btn/find-out-more-btn";

const HeroBannerImportant = () => {
  return (
    <div className="hero-banner hero-banner--maintenance ">
      <img
        className="hero-banner__background-image"
        src={HomepageBanner}
        type="image/jpeg"
        alt="High Value Mortgage Service Banner Background"
      />
      <div className="hero-banner__outer">
        <section className="hero-banner__content ">
          <h2>Supporting your customer’s everyday banking needs.</h2>
          <p>
            We are here to help, so once you have submitted an application, you can tell us about any additional support
            needs your customer may require by contacting our help desk on <a href="tel:03456005847">0345 600 5847</a>{" "}
            (please ensure you have their consent prior to contacting us).
          </p>
          <p>We'll only ever use this information to provide a better banking service.</p>
          <p>For a full list of support services available, please click find out more.</p>
          <FindOutMoreBtn />
        </section>
      </div>
    </div>
  );
};

export default HeroBannerImportant;
